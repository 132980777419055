import { getSdk, Sdk } from "@generated"
import { GraphQLClient } from "graphql-request"

export function getGraphQLApiUrl(): string {
  //
  // if process.env.VERCEL_ENV is defined, we're on the server side and need to return a full url
  //
  if (process.env.VERCEL_ENV) {
    if (process.env.VERCEL_ENV === "development") {
      return process.env.NEXT_PUBLIC_API_URL || "http://localhost:3000/graphql"
    }

    if (!process.env.VERCEL_URL) {
      throw new Error("process.env.VERCEL_URL is not defined")
    }
    return `https://${process.env.VERCEL_URL}/graphql`
  }

  //
  // if process.env.VERCEL_ENV is not defined, we're on the client side
  // then we can simply return the relative /graphql url
  //
  return "/graphql"
}

let client: GraphQLClient | null = null

export function getGraphQLClient(): {
  sdk: Sdk
  client: GraphQLClient
} {
  if (!client) {
    client = new GraphQLClient(getGraphQLApiUrl())
  }
  const sdk = getSdk(client)

  return { sdk, client }
}
